/**
 * @generated SignedSource<<c3e4babf306e7fa573a76b7a6f8ac3be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type LandingPageMode = "internal" | "external" | "none" | "%future added value";
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
export type ProductRegistrationAvailability = "public" | "private" | "hidden" | "%future added value";
export type ProductRegistrationType = "closed" | "open" | "application" | "waitlist" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExperienceSettingsForm_SettingsFragment$data = {
  readonly id: string;
  readonly organizationId: string;
  readonly slug: string;
  readonly badge: {
    readonly kind: BadgeKind;
    readonly icon: string | null;
    readonly color: string | null;
    readonly emoji: string | null;
    readonly mediaUrl: string | null;
  } | null;
  readonly name: string;
  readonly description: string | null;
  readonly cover: string;
  readonly typeTag: {
    readonly label: string;
    readonly color: string;
  } | null;
  readonly landingPageUrl: string | null;
  readonly landingPage: {
    readonly mode: LandingPageMode;
    readonly metaTitle: string | null;
    readonly metaDescription: string | null;
    readonly metaImageUrl: string | null;
  } | null;
  readonly status: ProductStatus;
  readonly startDate: string | null;
  readonly endDate: string | null;
  readonly startsAt: string | null;
  readonly endsAt: string | null;
  readonly waitingRoomEndsAt: string | null;
  readonly sendEarlyAccessStartedEmail: boolean;
  readonly registrationAvailability: ProductRegistrationAvailability;
  readonly registrationType: ProductRegistrationType;
  readonly waitlistUrl: string | null;
  readonly waitlistCtaLabel: string | null;
  readonly applicationQuestions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly richEditorBody: string | null;
        readonly type: string;
        readonly isRequired: boolean;
      };
    }>;
  };
  readonly isAutoJoined: boolean;
  readonly capacity: number | null;
  readonly hasPrice: boolean;
  readonly registrationPricing: {
    readonly amountCents: number;
    readonly basePrice: number;
    readonly kind: PricingKind;
    readonly frequency: PricingFrequency | null;
  } | null;
  readonly includedInBenefits: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pricing: {
          readonly id: string;
          readonly amountCents: number;
          readonly frequency: PricingFrequency | null;
          readonly kind: PricingKind;
        };
        readonly membershipPlan: {
          readonly id: string;
        };
      };
    }>;
  } | null;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"useExperienceSettingsSelectedPlans_membershipPlansFragment">;
  };
  readonly sendNewCommentEmail: boolean;
  readonly sendCourseRegistrationEmailToAttendees: boolean;
  readonly sendCourseReminderEmail: boolean;
  readonly sendAssignmentDueNotifications: boolean;
  readonly replyToEmailAddress: string | null;
  readonly richEditorCheckoutDescription: string | null;
  readonly " $fragmentType": "ExperienceSettingsForm_SettingsFragment";
};
export type ExperienceSettingsForm_SettingsFragment$key = {
  readonly " $data"?: ExperienceSettingsForm_SettingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceSettingsForm_SettingsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountCents",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frequency",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceSettingsForm_SettingsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "typeTag",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landingPageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LandingPage",
      "kind": "LinkedField",
      "name": "landingPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitingRoomEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendEarlyAccessStartedEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitlistUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitlistCtaLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionNodeConnection",
      "kind": "LinkedField",
      "name": "applicationQuestions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Question",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "richEditorBody",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isRequired",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAutoJoined",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RegistrationPricing",
      "kind": "LinkedField",
      "name": "registrationPricing",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePrice",
          "storageKey": null
        },
        (v1/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipBenefitNodeConnection",
      "kind": "LinkedField",
      "name": "includedInBenefits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipBenefitNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MembershipBenefit",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Pricing",
                  "kind": "LinkedField",
                  "name": "pricing",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "membershipPlan",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useExperienceSettingsSelectedPlans_membershipPlansFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendNewCommentEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendCourseRegistrationEmailToAttendees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendCourseReminderEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendAssignmentDueNotifications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replyToEmailAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorCheckoutDescription",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "c33b6c1eeaaea9df978e771a8b1b43d2";

export default node;
